import React from 'react'
import {
  Container,
  BannerText,
  Heading,
  TagLine,
  Animation,
  Grid,
  GetStarted,
} from './style'
import Lottie from 'react-lottie'
import animationData from './website-development.json'
import { StaticImage } from 'gatsby-plugin-image'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const Banner = () => {
  return (
    <Container>
      <Grid>
        <BannerText>
          <Heading>Welcome</Heading>
          <TagLine>
            My name is Anshul Manaktala, I'm a front-end developer based in
            London, UK. I have developed many types of front-ends from
            well know DJ applications to Ecommerce booking platforms. <br />
            <br />
            I'm passionate about cutting-edge, pixel-perfect, beautiful
            interfaces and intuitively implemented UX.
          </TagLine>
        </BannerText>
        <Animation>
          <StaticImage src="./turo.jpg" />
        </Animation>
      </Grid>
    </Container>
  )
}
export default Banner
