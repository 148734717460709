import React, { useState } from 'react'
import Border from '../Border/Border'
import { Container, Heading2 } from './style'
import { useTransition, animated } from 'react-spring'
import { Button } from '../Button/Button'

const Contact = () => {
  return (
    <Container>
      <Heading2>Send me a message</Heading2>
      <Border></Border>
      <form
        id="contact"
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        autocomplete="on"
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className="former">
          <label id="name">
            <div className="formitem">Name</div>
            <input type="text" name="name" autocomplete="name" required />
          </label>
          <label id="email">
            <div className="formitem">Email</div>
            <input type="text" name="email" autocomplete="email" required />
          </label>
          <label className="textdiv">
            <div className="formitem">Message</div>
            <textarea className="contactformarea" name="message" minLength={10} required></textarea>
          </label>
        </div>
        <Button type="submit">Send</Button>
      </form>
    </Container>
  )
}

export default Contact
